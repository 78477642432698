import React from 'react';
import CityGuide from '../../../../components/guides/CityComponent';
import Footer from '../../../../components/FooterComponent';

import { HoChiMinh } from '../../../../guides/countries/vietnam/ho-chi-minh-city';

function HoChiMinhPage(props) {
    return (
        <div>
            <CityGuide city={ HoChiMinh } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default HoChiMinhPage;
