import React from 'react';

export const HoChiMinh = {
    "name": "Ho Chi Minh City",
    "link": "ho-chi-minh-city",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380418026_1b3dd85542_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380418026_735d53055f_o.jpg",
    "caption": "Vietnamese culture on a modern city",

    "description": "Ho Chi Minh City, also referred as Saigon, is the largest city of Vietnam. The city made an impressive recovery from the Vietnam War, when most of the population had to be evacuated from the city as it felt to North Vietnam in 1975. Nowadays, it is a major business and financial hub for the country, and attracts crowds of tourists every year for it's blend of Vietnamese culture, classic French architecture and modern skyscrapers dotting the skyline. Saigon is a cosmopolitan city with over 21 million habitants within the metropolitan area, and has the best nightlife on all of Vietnam, alongside amazing street food.",

    "nrDays": <p>
            Saigon is a large city, and <b>2 days</b> is the bare minimum to grasp some of what it has to offer.
            We recommend you spend at least <b>3 days</b> here, then you will be able to visit all the main attractions, sample some of the amazing cuisine and street food from the city and enjoy the vibrant night life.
            <br/><br/>
            You can even spend up to <b>4 or 5 days</b> in the city and explore some of the less visited districts, enjoy the city views from the many rooftops or a have dinner in a cruise on the river. You can also take a day trip to <a href="/travel-guides/asia/vietnam/mekong-delta">Mekong Delta</a>.
        </p>,

    "whenVisit": <p>
            Unlike the northern part of Vietnam, the south enjoys a warm weather all year round, with averages of 28ºC.
            There are two seasons in the south: the dry season (from December to April) and the wet season (May to November).
            <br/><br/>
            Although Ho Chi Minh City can be enjoyed at any time of the year, we recommend you visit during the <b>dry season</b>, particularly on the months from <b>February to April</b>. You will get the best weather with few rainy days.
            <br/><br/>
            Avoid visiting the city during <b>Tet Holiday Season</b> (Vietnamese New Year), since it's usual for the locals to visit their hometown, meaning you will find the city deserted, most establishments closed and public transit will be unreliable.
        </p>,

    "mobility": <p>
        Saigon is a big city, with many motorbikes, narrow sidewalks and points of interest far away from each others, walking is not a good option. Until the metro system is complete (somewhere in 2021), your best alternative is either to rent a bicycle or motorbike if you are experienced enough (this is a chaotic city and it's easy to get on an accident if you don't know what you are doing) or using Grab.
        <br/><br/>
        A cheap option for those that get well on their own is by bus. There are buses to every major attraction in the city.
        <br/><br/>
        For those arriving from the airport and want to get to the city centre (district 1), you can either call a <a href="https://www.grab.com/" target="_blank" rel="noopener noreferrer">Grab</a> for around 5$ or catch a bus, the cheapest option. The bus station is right outside the arrival terminal, and you have three options: <b>bus 109</b> for 20.000VND (1$) and takes about 45 mins, <b>bus 49</b> for 40.000VND (2$) and takes 30 mins and the cheapest option <b>bus 152</b> for 5.000VND (0.25$) and takes about an hour.
    </p>,

    "safety": <p>
        Despite being a big, busy city, Ho Chi Minh is a safe place for travelers and solo backpackers. Still, you should exert the same level of awareness as in any other big city. Be careful with your personal belongings, even more on crowded places since pickpocketing can happen.
        <br/><br/>
        There are not many scams in Ho Chi Minh City, and most of them will just have you spend your money on as many things as they can sell you. Also, taxi drivers are known to scam tourists with higher prices, so always opt for Grab when possible.
        <br/><br/>
        As previously said, the transit in Ho Chi Minh City is chaotic, and you should be careful when crossing the street.
    </p>,

    "itinerary": {
        "description": "",
        "days": [
            {
                "description": <p>
                        Use the first day to explore on foot the attractions in District 1 and getting a feel for the city and its chaotic environment.
                        You can start with a visit to the <b>Norte-Dame Cathedral</b>, be sure to check the inside of the building as well.
                        Your next stop should be the <b>Central Post Office</b>, where you should look for the gorgeous hallway with Uncle Ho's portrait.
                        To round up the morning, you can visit the <b>Reunification Palace</b>, now converted to a museum about the Vietnam War.
                        <br/><br/>
                        After lunch you can learn a bit more about the Vietnamese history with a visit to the <b>War Remnants Museum</b>. You should spend around 2 to 3 hours inside the museum.
                        After it pay a visit to the Bitexco Financial Tower, where on the 49th is the <b>Saigon Skydeck</b>, with amazing views all over the city.
                        With much of the main attractions on the city centre visited, it's time for a walk on the main avenue of the city, <b>Nguyen Hue</b>. Here you will find many cafes and shops, and it's a popular spot where locals hang around on the afternoon. At the end of the avenue you will find the <b>City Hall</b>. Be sure to pay a visit to the <b>Cafe Apartments</b>, it's a building with one different cafe occupying each window.
                        <br/><br/>
                        For dinner immerse yourself on the street food scene of Ho Chi Minh City. Places like the <b>Ben Thanh Market</b> are the perfect place to explore and have dinner.
                        You should fully enjoy the nightlife of Ho Chi Minh City, <b>Bui Vien Street</b> is one of the most popular places to hang out until late at night.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50503109847_6d8b312a19_o.jpg",
                "caption": "The cafe apartments."
            },
            {
                "description": <p>
                        After an all night out the previous day, you will probably not be doing much on the morning the next day. You can use this time to sample of the cafe culture of Vietnam or walk along the <b>Vinhomes Central Park</b>.
                        For the afternoon, we recommend you do an excursion to the <b>Cu Chi Tunnels</b> and the <b>Cao Dai Temple</b>. Usually, companies sell day-tour packs for both attractions together, but if you are short on time and can only visit one, <b>Cu Chi Tunnels</b> is the one you can't miss.
                        <br/><br/>
                        For the evening, you can take a unique experience by <b>dinning on a cruise</b> along the Saigon River. Three popular options are:
                        <ul>
                            <li>Budget Option: Indochina Queen</li>
                            <li>Mid-Range Option: Bonsai Cruise</li>
                            <li>High-End Option: Saigon Princess Cruise Dining</li>
                        </ul>
                        The cruise dinner lasts about 2 to 3 hours, and there will be all sorts of entertainment during the experience.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50502956151_d80f772c84_o.jpg",
                "caption": "Saigon River with the dinner cruises."
            },
            {
                "description": <p>
                    On the last day it's time to visit the remaining attractions. Start with a visit to the <b>Ho Chi Minh City Museum</b>, where you can learn a bit more about the wars fought on Vietnam soil.
                    After the museum pay a visit to <b>Cholon</b>, the biggest Chinatown in Vietnam and one of the biggest in the world. There will be many shops here where you can buy all sorts of stuff. This is also a good place to grab something for lunch.
                    <br/><br/>
                    On the afternoon, if you are a big fan of art you much check the <b>Museum Of Fine Arts</b>. If art is not your thing, you can wander around some of the less traveled districts, or take part on a <b>Vietnamese Cooking Class</b>. You should also use this time to visit the <b>Emperor Jade Pagoda</b>.
                    <br/><br/>
                    For the evening, you can opt for another different experience and have dinner in one of the many rooftops of the city. The <b>Breeze Skybar</b> or the <b>Rex Rooftop Garden</b> and good options for this. After that you can watch a puppeteer show on the <b>Golden Dragon Water Puppet Theater</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50502956136_79327929aa_o.jpg",
                "caption": "Chaotic streets of Ho Chi Minh City."
            }
        ]
    },

    "highlights": [
        {
            "name": "War Remnants Museum",
            "description": "It has exhibitions about the First Indochina War and the Vietnam War. The highlight here is the Vietnam war, with its display of military equipment and tools used during the war. It should give you a good understanding of the war from the perspective of the locals. Entrance fee is about 2$ per person",
            "image": "https://live.staticflickr.com/65535/50503109782_624cc8ff36_o.jpg"
        },
        {
            "name": "Ho Chi Minh City Museum",
            "description": "Another museum about the Vietnam War and the war for independence from the French, with more focus on the use of photography and artifacts to tell the stories of both wars. Also has some exhibitions about the Vietnamese history and culture. Entrance fee is about 2$ per person",
            "image": "https://live.staticflickr.com/65535/50502956101_295efd1a1c_o.jpg"
        },
        {
            "name": "Museum Of Fine Arts",
            "description": "This is a big museum, consisting of 3 floors with exhibition galleries of contemporary Vietnamese and international art. The building itself is a gorgeous piece of architecture, blending French and Chinese styles. Entrance is about 1.50$ per person.",
            "image": "https://live.staticflickr.com/65535/50502238478_f94f280bf1_o.jpg"
        },
        {
            "name": "Ben Thanh Market",
            "description": "Located in the centre of District 1, this is the most famous and busiest market in Ho Chi Minh City, and draws crowds of locals and tourists everyday. On this market there are over 1500 stalls selling all sorts of products from food to souvenirs and clothes. If you loved this place consider also visiting the Ba Chieu Market.",
            "image": "https://live.staticflickr.com/65535/50502238453_96d335c0fd_o.jpg"
        },
        {
            "name": "Cholon - Chinatown",
            "description": "With around 200 years of history, this is the largest and oldest Chinatown in Vietnam. On the centre you will find the Binh Tay Market, where you can buy every type of item you can think of. There are also some temples in the area worth a visit, namely the Thien Hau Temple, the Quan Am Temple and the Cholon Mosque.",
            "image": "https://live.staticflickr.com/65535/50502956036_c6aea019b2_o.jpg"
        },
        {
            "name": "Emperor Jade Pagoda",
            "description": "Built in 1909 in honor of the Taoist god, Emperor Jade, this temple is the oldest you can find in all of Ho Chi Minh City. The building is decorated with sculptures and figurines of divinities and heroes.",
            "image": "https://live.staticflickr.com/65535/50502956016_092e4cca9e_o.jpg"
        },
        {
            "name": "Cao Dai Temple",
            "description": "Located 90 km away from Ho Chi Minh City, lies the first church of the Cao Dai religion. Founded in 1926, this religious site draws thousands of worships every year. The main attraction is the Great Divine Temple.",
            "image": "https://live.staticflickr.com/65535/50503109627_6a4c1f423c_o.jpg"
        },
        {
            "name": "Notre-Dame Cathedral",
            "description": "Built in the years between 1863 and 1880 by the French during the period of colonialism, it is one of the few remaining buildings dedicated to Catholicism in Vietnam. The two towers in the front rise at about 60 meters tall.",
            "image": "https://live.staticflickr.com/65535/50503109607_5ccd9ded57_o.jpg"
        },
        {
            "name": "Reunification Palace",
            "description": "Also known as the Independence Palace, this was the base of General Ngo Dinh Diem until his death in 1963. The palace has stopped in time, and you can see exhibitions of the Vietnam War.",
            "image": "https://live.staticflickr.com/65535/50502955936_e25331c3e5_o.jpg"
        },
        {
            "name": "Saigon Skydeck",
            "description": "The best viewpoint on all of town. Located on the 49th floor of the Bitexco Financial Tower, you will have a 360º panoramic view of the whole city. The entrance fee is about 9$ for adults and 6$ for kids and seniors. There is a restaurant on the 50th/51st floors with a nice atmosphere.",
            "image": "https://live.staticflickr.com/65535/50502238323_edd12d23f0_o.jpg"
        },
        {
            "name": "Cu Chi Tunnels",
            "description": "Located about an hour from Ho Chi Minh City, this is a network of tunnels used by the Viet Cong during the Vietnam war. Here you can walk through tunnels and chambers used by soldiers and civils to escape from the war. It's an experience not meant for anyone who is claustrophobic, as some tunnels are really narrow. Tours cost about 15$ per person. ",
            "image": "https://live.staticflickr.com/65535/50502238293_a073e7585f_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Saigon is known to have the biggest street food scene in all of Vietnam. In the past you would find stalls all over each others on every road of the city.
            It was not until 2017 that the government decided to move the street food vendors out of the pedestrian roads to designated places on the city, one example of such place is the <b>Ben Thanh Market</b>. Although not all the stalls decided to make the move, the ones with the most quality certainly did.
            <br/><br/>
            If you are searching for the best dishes, we give you an idea of what to sample for in each district.
            <br/><br/>
            <h5>District 4</h5>
            <ul>
                <li>Pha Lãu</li>
                <li>Ôc</li>
            </ul>
            <h5>District 5</h5>
            <ul>
                <li>Dumplings</li>
                <li>Fried Noodle Dishes</li>
                <li>Wontons</li>
            </ul>
            <h5>District 8</h5>
            <ul>
                <li>Barbecue</li>
                <li>Lãu Hotpot</li>
            </ul>
            <h5>District 10</h5>
            <ul>
                <li>Sweet Soup</li>
            </ul>
            However, if dinning in a normal restaurant is more what you are looking for, you will still have plenty of options in Saigon, and some of them quite unique.
        </p>,

        "restaurants": [
            {
                "name": "Pho Le",
                "description": "With two restaurants in the city, the specialty here is the Pho, a famous Vietnamese National Dish. The food is great and the prices are cheap.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502955866_3fa258b9b4_o.jpg"
            },
            {
                "name": "Banh Mi Huynh Hoa",
                "description": "Considered the best Banh Mi in town, one of the Vietnamese National Dishes. This restaurants is featured in many travel guides, and attracts a big queue of people everyday.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502955836_7181c853fd_o.jpg"
            },
            {
                "name": "Com Ga Xoi Mo Su Su",
                "description": "The best place in town for eating fried chicken, served with red rice and a secret sauce. The oil falls on the chickens like a waterfall, a unique method that leaves a special flavor on the plate. ",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50503109452_0a8d755fd8_o.jpg"
            },
            {
                "name": "Noir - Dining In The Dark",
                "description": "With a dinner in the dark, you will have to rely on your taste senses to identify what you are eating. This is a incredible experience in one of the most famous restaurants in Saigon.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50503109437_a392911bc3_o.jpg"
            },
            {
                "name": "Com Nieu Sai Gon",
                "description": "Made famous by the chef Anthony Bordain, the specialty here is the rice. Prepared in clay pots, it gives a unique flavor to the dish.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50502238153_d748ab54ee_o.jpg"
            },
            {
                "name": "The Alley Cocktail Bar",
                "description": "This is a great hidden gem in Saigon. The ambience and atmosphere is amazing, and there are a variety of cocktails.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50502955686_213edf2ba9_o.jpg"
            },
            {
                "name": "Whisfee Cafe",
                "description": "Located on 14 Ton That Dom building, this is one of the many cafes located on this \"abandoned\" building, known as the Cafe Apartments.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502238093_771eda2546_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
            There are two good districts where you should look for accommodation. <b>District 1</b> is the central district, where you will spend most of your time, be it for its attractions, for the incredible nightlife, for the restaurants and cafes or more. It's probably the place where you should stay if it's your first time in Saigon.
            <br/><br/>
            The downside of District 1 is the noise and chaotic environment, which is not ideal for relaxing or sleeping at all. If all this sets you off, consider staying in <b>District 3</b>, it's in close proximity to District 1, the prices are cheaper here and it also has a good range of restaurants and cafes.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "Nest Hotel",
                "description": "Located in what is considered the Backpackers's Area of Ho Chi Minh City, this hotel has some of the best rooms you will find on a budget. It's a short walk to many bars and restaurants, and although close to most nightlife of the city, is still far away enough to avoid the noises. ",
                "image": "https://live.staticflickr.com/65535/50502955626_b72ba5d8e6_o.jpg",
                "link": "/"
            },
            {
                "name": "Thien Hai Hotel",
                "description": "Located in the heart of District 1, near the Ben Thanh Market, this hotel is a good cheap option with small but clean and comfortable rooms. Has option for a good breakfast.",
                "image": "https://live.staticflickr.com/65535/50502955581_36239069c7_o.jpg",
                "link": "/"
            },
            {
                "name": "LeBlanc Saigon",
                "description": "This option on District 3 is a family-owned hotel located in a quiet part of the city, and offers clean and comfortable rooms.",
                "image": "https://live.staticflickr.com/65535/50502237998_3b08e3c2ca_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Lief Mojo Saigon",
                "description": "An incredible accomodation in the centre of District 1. With large, very clean rooms and comfortable bedrooms, this feels like a 5-star hotel with an amazing price. Boasts a bar, shared lounge and great views to the city.",
                "image": "https://live.staticflickr.com/65535/50502955511_d7d9e5f3a3_o.jpg",
                "link": "/"
            },
            {
                "name": "Chez Mimosa - Boutique Hotel",
                "description": "One of the top pick hotels in Ho Chi Minh City. It is located in the best area of the city for nightlife, and offers a great value for your money. They have a rooftop where guests can enjoy free drinks during happy hours and offers free laundry.",
                "image": "https://live.staticflickr.com/65535/50502237943_4b548b37e5_o.jpg",
                "link": "/"
            },
            {
                "name": "Orchids Saigon Hotel",
                "description": "Located on District 3, it has easy access to many commercial areas and tourist attractions. The rooms are comfortable and very clean and the staff is very friendly. Has an indoor pool and usually breakfast comes include with your stay.",
                "image": "https://live.staticflickr.com/65535/50502955456_7f7b1cb9e5_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "The Reverie Saigon",
                "description": "Perhaps the most luxurious hotel in all of Vietnam. Located in the heart of District 1, this is the perfect choice if you are eagar for the stay of your dreams. There is not a single bad thing we can pinpoint from this hotel.",
                "image": "https://live.staticflickr.com/65535/50502237898_7a3518ffa2_o.jpg",
                "link": "/"
            },
            {
                "name": "Hotel Des Arts Saigon",
                "description": "A luxurious hotel located in District 3. The building architecture is something to marvel at. Boast beautiful and clean rooms, a restaurant and an outdoor pool.",
                "image": "https://live.staticflickr.com/65535/50503109097_534c047a64_o.jpg",
                "link": "/"
            }
        ]
    }
}
